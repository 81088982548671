<template>
    <div class="page-content">
        <div class="park-form">
            <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                <el-form-item label="店铺" prop="name">
                    <el-select v-model="searchForm.store_id" clearable placeholder="请选择店铺">
                        <el-option
                                v-for="item in storeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>


                <el-form-item label="主题" prop="name">
                    <el-select v-model="searchForm.themes_is" clearable placeholder="请选择主题">
                        <el-option
                                v-for="item in themeList"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div class="fill-btn" @click="storeTheme">查询</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
                    <div class="fill-btn" @click="handShowAdd">新增</div>
                </el-form-item>
            </el-form>
        </div>

        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text">
                    <!--已选择{{selectData.length}}项-->
                </div>
            </div>
            <el-table :data="tableData" stripe
                      v-loading="loading"
                      @selection-change="handleSelectionChange"
                      cell-class-name="text-center"
                      header-cell-class-name="header-row-class-name">
                <el-table-column prop="store_name" label="店铺名称" min-width="120px"></el-table-column>
                <el-table-column prop="address" label="店铺地址" min-width="120px"></el-table-column>
                <el-table-column prop="themes_title" label="主题名称" min-width="120px"></el-table-column>
                <el-table-column prop="created_at" width="148px" label="创建时间"></el-table-column>
                <el-table-column width="120" fixed="right" label="操作">
                    <template slot-scope="{row}">
                        <div class="btn-content">
                            <el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
                                       @click="handEditShow(row)"></el-button>
                            <el-button class="el-icon-delete cur-p" type="primary" size="mini"
                                       @click="delUserTeacher(row)"></el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-content">
                <el-pagination
                        background
                        :current-page.sync="searchForm.page"
                        :page-size="searchForm.limit"
                        @current-change="searchForm.page=$event"
                        @size-change="handleSizeChange"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="创建店铺主题" :visible.sync="showAdd" width="600px">
            <div style="padding: 20px;">
                <el-form ref="form" :model="addTeacher" label-width="80px">
                    <el-form-item label="店铺">
                        <el-select v-model="addTeacher.store_id" clearable placeholder="请选择店铺">
                            <el-option
                                    v-for="item in storeList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="主题">
                        <el-select v-model="addTeacher.themes_id" multiple placeholder="请选择">
                        <el-option
                                v-for="item in themeList"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id">
                        </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="addType" style="margin: 10px auto">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>

        <el-dialog title="分类编辑" :visible.sync="showEdit">
            <div style="padding: 20px;">
                <el-form ref="form" :model="editForm" label-width="80px">
                    <el-form-item label="店铺">
                        <el-select v-model="editForm.store_id" clearable placeholder="请选择店铺">
                            <el-option
                                    v-for="item in storeList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="主题">
                        <el-select v-model="editForm.themes_id" clearable placeholder="请选择">
                            <el-option
                                    v-for="item in themeList"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="storeThemeSave" style="margin: 10px auto">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {delToast, formVerify} from './../../utlis/decorators'
    import RichText from './../../components/RichText'

    export default {
        name: "tenant_account",
        props: {
            parkId: {
                type: [Number, String],
                default: null
            },
        },
        components: {
            RichText
        },
        data() {
            return {
                dialogFormVisible: false,
                loading: false,
                currentTop: 0,
                total: 0,
                showAdd: false,
                showEdit: false,
                showEditPsd: false,
                editing: false,
                searchForm: {
                    page: 1,
                    limit: 10,
                    store_id: '',
                    themes_is: ''
                },
                form: {
                    nickname: ''
                },
                editForm: {
                    role_ids: [],
                    nickname: '',
                    username: '',
                    password: '',
                    status: ''
                },
                currentId: '',
                tableData: [],
                storeList: [],
                themeList: [],
                addTeacher: {
					store_id:'',
					themes_id:[],
				},
            }
        },
        created() {
            this.storeTheme();
            this.getStoreLists();
            this.getThemeLists();
        },
        computed: {
            getRules() {
                return {
                    role_ids: [{required: true, message: `角色不能为空`, trigger: ['blur', 'change']}],
                    nickname: [{required: true, message: `昵称不能为空`, trigger: ['blur', 'change']}],

                }
            }
        },
        watch: {
            'searchForm.page'() {
                this.storeTheme();
            },
        },
        methods: {
            //头像
            handleAvatarSuccess(res, file) {

                this.editForm.head = res.url;
                this.addTeacher.head = res.url;
                console.log(this.addTeacher.head);
            },

            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG,png 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }

            },
            handShowAdd() {
               try {
               	this.showAdd = true;
               	this.addTeacher.store_id = '';
               	this.addTeacher.themes_id = [];
               	this.$refs['form'].resetFields();           	
               } catch {}
            },
            handEditShow(row) {


                this.editForm = row;

                this.showEdit = true;
            },
            //页面数量改变
            handleSizeChange(val) {
                this.searchForm.page = 1;
                this.searchForm.limit = val;
            },
            handleSelectionChange(val) {
                this.selectData = val;
            },
            //获取列表
            async storeTheme() {

                console.log(this.searchForm);
                let [e, data] = await this.$api.storeTheme(this.searchForm);
                if (e) return;

                if (data.code === 200) {
                    this.tableData = data.data.data;

                    this.total = data.data.total;
                }
            },

            //获取店铺列表
            async getStoreLists() {

                let [e, data] = await this.$api.storeLists();
                if (e) return;
                if (data.code === 200) {
                    this.storeList = data.data;

                }
            },
            //获取主题
            async getThemeLists() {


                let [e, data] = await this.$api.themeLists();
                if (e) return;

                if (data.code === 200) {
                    this.themeList = data.data;

                }
            },

            @delToast()
            async delUserTeacher({id}) {

                let [e, data] = await this.$api.storeThemeDel({id});
                if (e) return;
                if (data.code === 200) {
                    this.storeTheme();
                    this.$message.success('删除成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            },


            //分配
            async addType() {
                this.loading = true;
                let [e, data] = await this.$api.storeThemeCreate(this.addTeacher);
                if (e) return;
                if (data.code === 200) {
                    // this.getTeacherList();
                    this.loading = false;
                    this.showAdd = false;
                    this.storeTheme();
                    this.$message.success('添加成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            },
            //类型编辑
            async storeThemeSave() {
                this.editing = true;
                let [e, data] = await this.$api.storeThemeSave(this.editForm);
                this.editing = false;
                if (e) return;
                if (data.code === 200) {
                    this.showEdit = false;
                    this.storeTheme();
                    this.$message.success('保存成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            }
        },
    }
</script>
<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 20px;
            color: rgb(190, 199, 213);
            margin: 0 10px;
            cursor: pointer;
        }

        .icon-shanchu {
            font-size: 24px;
        }
    }

    .box-shadow {
        width: calc(~'100% + 40px');
        height: 50px;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        box-shadow: 0 0 4px #b4b4b4;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
